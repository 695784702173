import Card from "./Card"
import { projectsFirstColumn, projectsSecondColumn } from "../data/projectData"
import VideoModal from "./VideoModal"
import { useState } from "react"

export default function Projects() {
  const [open, setOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")
  const [projectName, setProjectName] = useState("")
  const [image, setImage] = useState("")

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-baseline sm:justify-between"></div>

        <div className="mt-2 grid grid-cols-1 gap-y-10 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 lg:gap-x-10">
          <div>
            <h2 className="text-7xl pb-8 sm:py-20 lg:py-20 font-bold tracking-tight text-gray-900  text-center sm:text-left lg:text-left">
              Projects
            </h2>
            {projectsFirstColumn.map((project) => (
              <div key={project.id} className="pb-8">
                <Card
                  name={project.name}
                  content={project.content}
                  imageSrc={project.imageSrc}
                  imageAlt={project.imageAlt}
                  tags={project.tags}
                  link={project.link}
                  githubLink={project.githubLink}
                  colour={project.colour}
                  video={project.video}
                  onClick={() => {
                    setVideoUrl(project.video)
                    setProjectName(project.name)
                    setImage(project.imageSrc)
                    setOpen(true)
                  }}
                />
              </div>
            ))}
          </div>
          <div>
            {projectsSecondColumn.map((project) => (
              <div key={project.id} className="pb-8">
                <Card
                  name={project.name}
                  content={project.content}
                  imageSrc={project.imageSrc}
                  imageAlt={project.imageAlt}
                  tags={project.tags}
                  link={project.link}
                  githubLink={project.githubLink}
                  colour={project.colour}
                  video={project.video}
                  onClick={() => {
                    setVideoUrl(project.video)
                    setProjectName(project.name)
                    setImage(project.imageSrc)
                    setOpen(true)
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <VideoModal
        projectName={projectName}
        open={open}
        setOpen={setOpen}
        video={videoUrl}
        image={image}
      />
    </div>
  )
}
