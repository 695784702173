import Chip from "./Chip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithubAlt } from "@fortawesome/free-brands-svg-icons"
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"

export default function Card({
  name,
  content,
  imageSrc,
  imageAlt,
  tags,
  link,
  githubLink,
  colour,
  video,
  onClick,
}) {
  const chipColours = [
    "bg-[#D9FFFC] text-[#2CB67D]",
    "bg-[#e8e4ff] text-[#846CFD]",
    "bg-[#D9F0FF] text-[#2B6CB0]",
    "bg-[#FED7E2] text-[#C02652]",
    "bg-[#FFF4B3] text-[#F59E0B]",
    "bg-[#FFD6A5] text-[#D97706]",
  ]
  return (
    <div className="overflow-hidden rounded-lg bg-white border">
      <div className="px-4 py-5 sm:px-6 bg-slate-100 text-left">
        <div className={`flex place-content-between items-center $`}>
          <div>
            <h2 className="text-lg font-semibold text-gray-950">{name}</h2>
          </div>
          <div className="flex gap-3">
            {link && (
              <a href={link} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  className="h-5 w-5 text-black bg-white border border-slate-400 p-2 inline-block rounded-md transition ease-in hover:bg-black hover:text-white hover:border-black"
                  icon={faArrowUpRightFromSquare}
                />
              </a>
            )}
            {githubLink && (
              <a href={githubLink} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  className="h-5 w-5 text-black bg-white border border-slate-400 p-2 inline-block rounded-md transition ease-in hover:bg-black hover:text-white hover:border-black"
                  icon={faGithubAlt}
                />
              </a>
            )}
          </div>
        </div>
        <div className="mt-4 text-sm text-slate-900">{content}</div>
      </div>
      <div>
        <img
          src={imageSrc}
          alt={imageAlt}
          className="object-cover cursor-pointer"
          onClick={onClick}
        />
      </div>
      <div className="px-4 py-4 sm:px-6">
        {tags.map((tag, index) => (
          <Chip
            text={tag}
            key={tag}
            colour={chipColours[index % chipColours.length]}
          />
        ))}
      </div>
    </div>
  )
}
