export const projectsFirstColumn = [
  {
    id: 1,
    name: "CatGPT  😺",
    content: (
      <>
        <p>
          CatGpt is a fun twist on traditional chatbots! I developed this
          project using the OpenAI API, with a backend built on Express and a
          React frontend. The unique feature of CatGpt is that it responds like
          a cat, adding a playful touch to the conversation.
        </p>
        <ul className="list-disc list-inside pl-4">
          <li>
            Utilizes the <strong>GPT-4o</strong> model for intelligent responses
          </li>
          <li>
            Interactive chat interface built with <strong>React</strong>
          </li>
          <li>
            Styled using <strong>Tailwind CSS</strong> for a sleek look
          </li>
        </ul>
        <p>
          The animated cat was designed by me. Whether you need advice or just
          want to chat, CatGpt is here to purrfect your day!
        </p>
      </>
    ),
    imageSrc: "images/catGPT.png",
    imageAlt: "CatGPT",
    tags: [
      "OpenAI API",
      "Node.js",
      "Express",
      "React",
      "Tailwind CSS",
      "Procreate",
    ],
    link: "https://cat-gpt-delta.vercel.app/",
    githubLink: "https://github.com/sarawasim/catGPT",
    colour: "bg-[#FFF4B3]",
    video: "images/catGpt.mov",
  },
  {
    id: 2,
    name: "SQL Query Generator",
    content: (
      <>
        <p>
          The SQL Query Generator is an intuitive tool that transforms natural
          language inputs into SQL queries using the <strong>GPT-4o</strong>{" "}
          model via OpenAI's chat completions endpoint. This project features a
          sleek interface built with <strong>React</strong> and styled using{" "}
          <strong>Tailwind CSS</strong>, making it easy to generate complex SQL
          queries without needing to know the syntax.
        </p>
        <p>
          Once you input your query request, the generator processes your text
          and provides the corresponding SQL query. You can then copy the
          generated query to your clipboard with the click of a button.
        </p>
        <h3>Key Features:</h3>
        <ul className="list-disc list-inside pl-4">
          <li>
            <strong>Natural Language Processing</strong>: Convert plain text
            descriptions into accurate SQL queries.
          </li>
          <li>
            <strong>User-Friendly Interface</strong>: Enjoy a clean, responsive
            design that facilitates easy interaction.
          </li>
          <li>
            <strong>Clipboard Functionality</strong>: Quickly copy queries for
            immediate use in your database applications.
          </li>
        </ul>
      </>
    ),
    imageSrc: "images/sqlQueryGenerator.png",
    imageAlt: "SQL Query Generator",
    tags: [
      "TypeScript",
      "OpenAI API",
      "Node.js",
      "Express",
      "React",
      "Tailwind CSS",
      "CSS",
    ],
    link: "https://sql-query-generator-eight.vercel.app/",
    githubLink: "https://github.com/sarawasim/sqlQueryGenerator",
    colour: "bg-[#D9FFFC]",
    video: "images/sqlQueryGenerator.mov",
  },
]

export const projectsSecondColumn = [
  {
    id: 3,
    name: "JavaScript Nightmares",
    content: (
      <>
        <p>
          This is the first game I ever created, it has always been a passion of
          mine to bring games to life. I followed a tutorial but added several
          features of my own, including:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li>
            Pressing <strong>space to start</strong> the game
          </li>
          <li>
            A <strong>score counter</strong> to track your progress
          </li>
          <li>
            Pressing <strong>space to restart</strong> if you die
          </li>
        </ul>
        <p>
          The character's name is Bob, and he is learning how to code! But...
          it's not easy. Every JavaScript block he jumps over gets him one skill
          point closer to becoming a better programmer.
        </p>
        <p>
          All artwork was created entirely by me (if you couldn't tell lol)
          using Procreate.
        </p>
      </>
    ),
    imageSrc: "images/bobJumps.png",
    imageAlt: "Bob Jumps",
    tags: ["JavaScript", "Html", "Css", "Procreate"],
    link: "https://bob-jumps.vercel.app/",
    githubLink: "https://github.com/sarawasim/bobJumps",
    colour: "bg-[#FFCAE3]",
    video: "images/bobJumps.mov",
  },
  {
    id: 4,
    name: "Collection Tracker App",
    content: (
      <>
        <p>
          This project was developed as part of my studies at BCIT, where we
          collaborated with a project sponsor. Our team of six students from the
          Full Stack Web Development and Digital Design and Development programs
          worked together to create the first prototype for this application.
        </p>
        <p>
          Collection Tracker is a waste tracking and supply chain management
          analytics tool for businesses. It enables companies to track their
          generated waste, communicate this information to a collector or end
          processor, and obtain analytics on amounts repurposed and diverted
          from landfills.
        </p>
      </>
    ),
    imageSrc: "images/collectionTrackerApp.png",
    imageAlt: "Collection Tracker App",
    tags: ["Postgres", "Express.js", "ReactJS", "Node.js", "Heroku", "Jira"],
    link: "",
    githubLink: "https://github.com/jojo-lam/collectiontrackerapp",
    colour: "bg-[#B5D0FF]",
  },
  {
    id: 5,
    name: "Dynamic Canvas Animation",
    content: (
      <>
        <p>
          I've always been intrigued by all the possibilities that the HTML5
          Canvas element offers. This project is a fun experiment that combines
          my love for design and coding. I created this by following a tutorial
          and experimenting with different features.
        </p>
      </>
    ),
    imageSrc: "images/canvas.png",
    imageAlt: "Dynamic Canvas Animation",
    tags: ["JavaScript", "HTML5", "CSS", "Canvas"],
    link: "https://canvas-two-theta.vercel.app/",
    githubLink: "https://github.com/sarawasim/canvas",
    colour: "bg-[#D9FFFC]",
    video: "images/canvas.mov",
  },
]
