import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline"
import { NavLink } from "react-router-dom"

export default function Nav() {
  return (
    <Disclosure as="nav" className="bg-white shadow-sm sticky top-0">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="-ml-2 mr-2 flex items-center md:hidden">
              {/* Mobile menu button */}
              <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none ">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                <Bars3Icon
                  aria-hidden="true"
                  className="block h-6 w-6 group-data-[open]:hidden"
                />
                <XMarkIcon
                  aria-hidden="true"
                  className="hidden h-6 w-6 group-data-[open]:block"
                />
              </DisclosureButton>
            </div>
            <div className="flex-col flex-shrink-0 text-left mt-2">
              <div className="text-2xl">Sara Wasim</div>
              <div className="text-xs text-gray-500">
                FULL STACK WEB DEVELOPER & UI/UX DESIGNER
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <div className="hidden md:ml-6 md:flex md:space-x-8">
                {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                <NavLink
                  to="/"
                  // className="inline-flex items-center px-1 pt-1 text-sm  text-gray-900 font-semibold"
                  className={({ isActive }) => {
                    return isActive
                      ? "inline-flex items-center px-1 pt-1 text-sm  text-gray-900 font-semibold"
                      : "inline-flex items-center border-transparent px-1 pt-1 text-sm  text-gray-500 hover:text-gray-700"
                  }}
                >
                  PROJECTS
                </NavLink>
                <NavLink
                  to="/about"
                  className={({ isActive }) => {
                    return isActive
                      ? "inline-flex items-center px-1 pt-1 text-sm  text-gray-900 font-semibold"
                      : "inline-flex items-center border-transparent px-1 pt-1 text-sm  text-gray-500 hover:text-gray-700"
                  }}
                >
                  ABOUT
                </NavLink>
                <a
                  href="assets/saraWasimResume.pdf"
                  className="inline-flex items-center border-transparent px-1 pt-1 text-sm  text-gray-500 hover:text-gray-700"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RESUME
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DisclosurePanel className="md:hidden">
        <div className="space-y-1 pb-3 pt-2">
          {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
          <NavLink
            to="/"
            // className="block border-l-4 border-[#846CFD] bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-[#846CFD] sm:pl-5 sm:pr-6"
            className={({ isActive }) => {
              return isActive
                ? "block border-l-4 border-[#846CFD] bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-[#846CFD] sm:pl-5 sm:pr-6"
                : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
            }}
          >
            PROJECTS
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) => {
              return isActive
                ? "block border-l-4 border-[#846CFD] bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-[#846CFD] sm:pl-5 sm:pr-6"
                : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
            }}
          >
            ABOUT
          </NavLink>
          <DisclosureButton
            as="a"
            href="assets/saraWasimResume.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
          >
            RESUME
          </DisclosureButton>
        </div>
      </DisclosurePanel>
    </Disclosure>
  )
}
